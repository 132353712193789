<script>
import PcisSteps from "@/components/PCISSteps.vue";
import * as common from "@/libs/common";

export default {
  name: "BaseStep",
  components: { PcisSteps },
  data() {
    return {
    }
  },
  methods: {
    t(section, key) {
      return common.t_p(this.$options.i18nIndex, section, key);
    },
    tm(section, key) {
      return common.tm_p(this.$options.i18nIndex, section, key);
    },
  },
  computed: {
    formType() {
      return this.$store.getters.formType;
    },
    isLoading() {
      return this.loading ? "is-load" : ""
    }
  }
};
</script>

<style>
.is-load {
  visibility: collapse;
}
</style>
