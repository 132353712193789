<template>
  <div v-if="activeIndex !== null && loading == false">
    <el-row class="steps-bar">
      <el-col
        :xs="24"
        :sm="{ span: 20, offset: 2 }"
        :lg="{ span: 16, offset: 4 }"
      >
        <el-steps
          :active="activeIndex"
          process-status="finish"
          finish-status="success"
          align-center
        >
          <el-step
            v-for="step in getDisplaySteps(steps)"
            :key="step[steps.key]"
            :title="getStepText(step)"
          />
        </el-steps>
      </el-col>
      <el-col class="title">
        <label v-text="currentTitle" />
      </el-col>
    </el-row>
    <el-row>
      <el-col :sm="{ span: 20, offset: 2 }" :lg="{ span: 18, offset: 3 }" class="steps-content">
        <slot />
      </el-col>
    </el-row>
    <slot name="buttons" v-if="enableButtons" >
      <el-row :gutter="10" class="steps-button">
        <el-col :span="12">
          <el-button type="default" round @click="onPrevClick(this, $event)">
            <span v-text="$t('common.button.back')" />
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" round @click="onNextClick(this, $event)">
            <span v-text="$t('common.button.next')" />
          </el-button>
        </el-col>
      </el-row>
    </slot>
  </div>
  <div v-else style="height: calc(100vh - 120px);" v-loading="true" />
</template>

<style>

</style>

<style scoped>
.steps-bar {
  background-color: rgba(17, 37, 74, 0.85);
  padding: 10px 0px;
}

.steps-bar .title {
  display: none;
  color: #ffffff;
  text-align: center;
  -webkit-text-stroke: 0.8px #409eff;
  font-size: 20px;
  margin-top: 15px;
}

div >>> .steps-content .el-card {
  margin-top: 20px;
}

.steps-button {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 650px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.steps-button >>> .el-button {
  width: 100%;
  max-width: 300px;
}

@media screen and (max-width: 767px) {
  .steps-button {
    margin-left: 20px;
    margin-right: 20px;
    padding: 0px 20px;
  }
}

@media screen and (max-width: 576px) {
  div >>> .el-step__title {
    display: none;
  }

  .steps-bar .title {
    display: initial;
  }
}

@media screen and (max-width: 767px) {
  .steps-content >>> .el-card {
    margin-left: 20px;
    margin-right: 20px;
  }
}
</style>

<script>
import navigation from "@/libs/navigation";
import constants from "@/libs/constants";
import * as common from '@/libs/common.js';
import authentication from "@/http/apis/personal/authentication";
import application from "@/http/apis/personal/application";
// import * as message from "@/plugins/message.js"

export default {
  name: "PCISSteps",
  data() {
    return {
      activeIndex: null,
      currentStep: null,
      steps: null,
      loading: true
    }
  },
  props: {
    enableButtons: {
      type: Boolean,
      default: true,
    },
    formType: {
      type: String,
      default: constants.formType.new
    }
  },
  emits: ["prevClick", "nextClick", "trigger"],
  async created() {
    let jwt = this.$store.state.personal.jwt;
    let locale = this.$i18n.locale;
    let token = null;
    let isFirstUse = false;
    let currentRoute = this.$router.currentRoute.value;
    if (Object.prototype.hasOwnProperty.call(currentRoute.query,"token")) {
      token = currentRoute.query.token;
      isFirstUse = true;
    }
    if (!jwt) {
      if (!token) {
        this.$router.push({name: "PersonalUnauthorized", params: { locale: locale }});
        return false;
      }
    } else {
      let jwtObj = common.parseJWT(jwt);
      if (isFirstUse) {
        if (token !== jwtObj.token) {
          this.$store.commit("resetPersonalState");
          this.goFirst();
        }
      } else {
        if (!common.authenticateJWT(jwt)) {  
          token = jwtObj.token;
        }
      }
    }
    
    if (token) {
      // let res = await common.authenticateToken(token);
      if(!isFirstUse) {
        common.handlePersonalSessionExpired(token);
      } else {
        let res = await common.authenticateToken(token);
        if (!res) return;
      }
    } else {
      // console.log("refresh")
      authentication.postRefreshJWT(jwt);
    }
    
    navigation.initSteps(this.formType);
    this.steps = navigation.steps;
    let options = this.$parent.$options;
    let currentView = options.name;
    let step = navigation.getByViewName(currentView);
    if (!step) {
      this.goFirst();
      return false;
    }
    options.i18nIndex = step.i18nIndex ?? step.name;
    this.activeIndex = step.activeIndex??navigation.getIndex(step);
    this.currentStep = step;
    await this.authenticateAccess(step);
    this.loading = false
  },
  computed: {
    currentTitle() {
      return this.getStepText(this.currentStep);
    }
  },
  mounted() {
    document.body.scrollIntoView();
  },
  methods: {
    getStepText(step) {
      return !step || step.name == ""
        ? null
        : this.$i18n.t(`personal.steps.${step.name}`);
    },
    onPrevClick(event, button) {
      this.$emit('prevClick', event, button);
    },
    onNextClick(event, button) {
      this.$emit('nextClick', event, button);
    },
    goFirst() {
      navigation.goFirst();
    },
    goPrev() {
      let res = this.isSessionExpired();
      if(res) return false;
      navigation.goPrev(this.currentStep)
    },
    goNext() {
      let res = this.isSessionExpired();
      if(res) return false;
      navigation.goNext(this.currentStep)
    },
    goForm() {
      navigation.goForm();
    },
    goPayment() {
      navigation.goStep(navigation.getByViewName("PersonalPayment"))
    },
    async authenticateAccess(step) {
      let personal = this.$store.state.personal;
      if(personal.complete) {
        navigation.goStep(this.steps[this.steps.length - 1])
        return;
      }
      if(step.requireAuthen) {
        if(!personal.agreement) {
          this.goFirst();
          return false;
        }
        if(!personal.form.authen && step.viewName != "PersonalForm" ) {
          this.goForm();
          return false;
        }
      }
      if(step.requireVerified) {
        if(!personal.form.verified) {
          this.goForm();
          return false;
        }
      }
      if(personal.form.id && step.viewName != "PersonalPaymentProcess") {
        switch (step.viewName) {
          case "PersonalComplete": {
            this.$store.commit("resetPersonalForm")
            let id = this.$store.state.personal.form.id;
            if(!id) {
              this.$refs.steps.goFirst();
              return;
            }
            if(personal.form.type == 'N') {
              let res = await common.handlePayment(personal.form.id, false)
              if(res.status < 4) {
                this.$store.commit("updateStatusForPayment");
                this.goPayment();
                return;
              }
            }
            if (!this.$store.state.personal.complete) {
              application.postSendSubmissionEmail(this.$store.getters.personalJWT, id).catch((err) => {
                console.log(err)
              })
            }
            this.$store.commit("updateComplete", true)
            break;
          }
          case "PersonalPayment": {
            this.$emit("trigger");
            break;
          }
          case "PersonalPaymentProcess":
            break;
          default: {
            switch (personal.form.type) {
              case "N":
                this.$store.commit("updateStatusForPayment");
                this.goPayment();
                break;
              case "R":
                navigation.goStep(this.steps[this.steps.length - 1])
                break;
            }
          }
        }
      }
    },
    isSessionExpired() {
      let res = !common.authenticateJWT(this.$store.getters.personalJWT);
      if(res) common.handlePersonalSessionExpired();
      return res;
    },
    getDisplaySteps(steps) {
      return steps.filter((obj) => {
        return !Object.prototype.hasOwnProperty.call(obj, "activeIndex")
      })
    }
  }
}
</script>
