import http from "@/http"
import { createFormSubmissionObj } from "@/libs/common"

let modulePath = "personal/Application";

const post = function(path, data, config = {}) {
  return http.post(`${modulePath}/${path}`, data, config);
}

export default  {
  postFormValidate(jwt, dataObj) {
    let obj = createFormSubmissionObj(dataObj);
    obj.photo = null;
    return post("validation", obj, {
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json"
      }
    })
  },
  postSubmitForm(jwt, dataObj) {
    return post("submit", createFormSubmissionObj(dataObj), {
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json"
      }
    })
  },
  postSendSubmissionEmail(jwt, formId) {
    return post("sendSubmissionEmail", null, {
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json"
      },
      params: {
        formId: formId,
        isResend: false
      }
    })
  }
}